import { useForm } from 'react-hook-form'
import { useCallback, useState } from 'react'

import {
  convertDateToString,
  convertStringToDate,
  isValidDate,
} from 'utilities/datepicker'
import { getPatrimonyFragment, useToast } from 'shared/hooks'
import { queryEventGroup } from 'shared/hooks/services/events'
import { fetchAccounts } from 'shared/hooks/accounts/useGetAccounts'

import {
  ButtonV2,
  Checkbox,
  ComboBoxV3,
  Datepicker,
  FormItem,
  FormLabel,
  Icon,
  SelectV2,
  Tag,
} from 'components'

import {
  accountDetailsReport,
  accountEventsReport,
  Form,
  parameterOptions,
  reports,
  ReportsType,
  reportTypes,
} from 'services/report'

import styles from './styles.module.scss'
import { getAccount } from 'services'
import { useCustomerContext } from '../CustomerManagementTabs/CustomerProvider'

const handleFormatDateValue = (value: string) => {
  if (isValidDate(value) && value.length > 9) {
    const date = convertStringToDate(value)

    return date.getTime()
  }
}

const handleGetEventGroups = async (searchFilter: string, offset = 0) =>
  await queryEventGroup(offset, searchFilter)

const handleDownload = (url: string, type: ReportsType, accountId: string) => {
  const tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute(
    'download',
    `report_account_${accountId}_${type === 'account' ? 'details' : 'events'}.pdf`,
  )

  document.body.appendChild(tempLink)
  tempLink.click()

  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(url)
}

const Reports = () => {
  const { customer, account: accountContext } = useCustomerContext()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast()

  const customerId = customer?.id || ''
  const accountId = accountContext?.id || ''

  const { setValue, watch, handleSubmit } = useForm<Form>({
    defaultValues: async () => {
      const account = await getAccount(accountId)

      return {
        ...(account && {
          patrimony: {
            id: account.patrimony?.id,
            name: account.patrimony?.name,
          },
          account: {
            id: account.account.id,
            code: account.account.code,
            aggregatedName: `${account.account.code} - ${account.account.name}`,
          },
        }),
        parameter: ['contacts', 'devices', 'users'],
      }
    },
  })

  const {
    account,
    type,
    patrimony,
    eventGroup,
    parameter,
    endDate,
    startDate,
  } = watch()

  const handleGetPatrimonies = (searchFilter: string, offset = 0) => {
    return getPatrimonyFragment(customer?.id || '', offset, searchFilter)
  }

  const handleGetAccounts = useCallback(
    async (searchFilter: string, offset = 0) => {
      const response = await fetchAccounts(offset, 15, customer?.id, {
        ...(searchFilter && {
          code: searchFilter,
        }),
      })

      if (patrimony?.id) {
        const filteredByPatrimony = response.data.filter(
          (account) => account.patrimony?.id === patrimony.id,
        )

        return {
          data: filteredByPatrimony,
          totalElements: filteredByPatrimony.length,
        }
      }
      return response
    },
    [customerId],
  )

  const onSubmit = useCallback(
    async ({
      account,
      parameter,
      startDate,
      endDate,
      eventGroup,
      type,
    }: Form) => {
      try {
        setLoading(true)

        const submitType: Record<
          ReportsType,
          () => Promise<Blob | MediaSource>
        > = {
          account: async () =>
            await accountDetailsReport({
              accountId: account.id,
              userId: customerId,
              showContacts: !!parameter.find((param) => param === 'contacts'),
              showUsers: !!parameter.find((param) => param === 'users'),
              showZones: !!parameter.find((param) => param === 'devices'),
            }),

          event: async () =>
            await accountEventsReport({
              userId: customerId,
              createdTo: endDate,
              accountId: account.id,
              createdFrom: startDate,
              eventGroupIds:
                eventGroup?.map((eventgroup) => eventgroup.id) || [],
            }),
        }

        const response = await submitType[type]()

        const url = URL.createObjectURL(response)
        handleDownload(url, type, account.id)
        addToast({
          message: 'PDF salvo com sucesso.',
          type: 'success',
        })
      } catch (error) {
        addToast({
          message: 'Erro ao Gerar PDF. Tente novamente.',
          error: true,
          type: 'alert',
        })
      } finally {
        setLoading(false)
      }
    },
    [customerId],
  )

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormItem>
          <FormLabel>Patrimônio</FormLabel>
          <ComboBoxV3.Root findOptions={handleGetPatrimonies} valueKey="id">
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field value={patrimony?.name || ''} />
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map((option) => (
                    <ComboBoxV3.Option
                      key={option.id}
                      onClick={() => {
                        setValue(
                          'patrimony',
                          {
                            id: option.id,
                            name: option?.name,
                          },
                          {
                            shouldValidate: true,
                          },
                        )
                      }}
                    >
                      {option.name}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
        </FormItem>

        <FormItem>
          <FormLabel>Conta</FormLabel>
          <ComboBoxV3.Root
            findOptions={handleGetAccounts}
            valueKey="aggregatedAccountName"
          >
            {({ unSelectedOptions }) => (
              <>
                <ComboBoxV3.Field value={account?.aggregatedName || ''} />
                <ComboBoxV3.Group>
                  {unSelectedOptions?.map((option) => (
                    <ComboBoxV3.Option
                      key={option.id}
                      onClick={() => {
                        setValue(
                          'account',
                          {
                            id: option.id,
                            code: option?.code || '',
                            aggregatedName: option.aggregatedAccountName,
                          },
                          {
                            shouldValidate: true,
                          },
                        )

                        if (
                          !!option.patrimony &&
                          (!patrimony?.id ||
                            patrimony?.id !== option.patrimony?.id)
                        )
                          setValue(
                            'patrimony',
                            {
                              id: option.patrimony?.id,
                              name: option?.patrimony?.name,
                            },
                            {
                              shouldValidate: true,
                            },
                          )
                        handleGetAccounts('', 0)
                      }}
                    >
                      {option.aggregatedAccountName}
                    </ComboBoxV3.Option>
                  ))}
                </ComboBoxV3.Group>
              </>
            )}
          </ComboBoxV3.Root>
        </FormItem>

        <FormItem>
          <FormLabel>Tipo de relatório</FormLabel>
          <SelectV2.Root data={reportTypes} valueKey="value">
            {({ unSelectedOptions }) => (
              <>
                <SelectV2.Field
                  value={type ? reports[type] : ''}
                  placeholder="Selecione um tipo de relatório"
                />
                <SelectV2.Group>
                  {unSelectedOptions?.map((option) => (
                    <SelectV2.Option
                      key={option.value}
                      onClick={() => {
                        setValue('type', option.value, {
                          shouldValidate: true,
                        })
                      }}
                    >
                      {option.name}
                    </SelectV2.Option>
                  ))}
                </SelectV2.Group>
              </>
            )}
          </SelectV2.Root>
        </FormItem>

        {type === 'event' && (
          <>
            <FormItem>
              <FormLabel>Tipo de evento</FormLabel>
              <ComboBoxV3.Root
                findOptions={handleGetEventGroups}
                valueKey="description"
              >
                {({ unSelectedOptions }) => {
                  return (
                    <>
                      <ComboBoxV3.Field
                        placeholder={!eventGroup?.length ? 'Todos' : ''}
                        value={eventGroup}
                      />
                      <ComboBoxV3.Group>
                        {unSelectedOptions?.map((option) => (
                          <ComboBoxV3.Option
                            key={option.id}
                            shouldCloseGroup={false}
                            onClick={() => {
                              setValue(
                                'eventGroup',
                                [
                                  ...(eventGroup || []),
                                  {
                                    id: option.id,
                                    description: option.description,
                                  },
                                ],
                                {
                                  shouldValidate: true,
                                },
                              )
                            }}
                          >
                            {option.description}
                          </ComboBoxV3.Option>
                        ))}
                      </ComboBoxV3.Group>
                      {!!eventGroup?.length && (
                        <>
                          <ComboBoxV3.Options>
                            {eventGroup.map((group) => (
                              <Tag key={group.id}>
                                <span>{group.description}</span>

                                <Icon
                                  name="close-xlg"
                                  width={8}
                                  height={8}
                                  onClick={() =>
                                    setValue(
                                      'eventGroup',
                                      eventGroup.filter(
                                        (item) => item.id !== group.id,
                                      ),
                                    )
                                  }
                                />
                              </Tag>
                            ))}
                          </ComboBoxV3.Options>
                          <ButtonV2
                            size="md"
                            appearance="tertiary"
                            onClick={() => setValue('eventGroup', [])}
                          >
                            <Icon name="delete" width={12} height={12} />
                            Remover tudo
                          </ButtonV2>
                        </>
                      )}
                    </>
                  )
                }}
              </ComboBoxV3.Root>
            </FormItem>

            <FormItem className={styles.options}>
              <FormLabel>Período</FormLabel>
              <div>
                <FormLabel>De</FormLabel>
                <Datepicker
                  id="startDate"
                  initialValueInput={
                    startDate
                      ? convertDateToString(new Date(startDate))
                      : undefined
                  }
                  initialDate={startDate ? new Date(startDate) : undefined}
                  onChangeInput={(event) => {
                    const formattedValue = handleFormatDateValue(
                      event.target.value,
                    )

                    if (formattedValue) {
                      setValue('startDate', formattedValue)
                    }
                  }}
                  onChangeDate={(date) => {
                    const dateTime = date?.getTime()

                    if (dateTime) {
                      setValue('startDate', dateTime)
                    }
                  }}
                />
                <FormLabel>até</FormLabel>
                <Datepicker
                  id="endDate"
                  selectEnd
                  disabled={!startDate}
                  initialValueInput={
                    endDate ? convertDateToString(new Date(endDate)) : undefined
                  }
                  initialDate={startDate ? new Date(startDate) : undefined}
                  onChangeInput={(event) => {
                    const formattedValue = handleFormatDateValue(
                      event.target.value,
                    )

                    if (formattedValue) {
                      setValue('endDate', formattedValue)
                    }
                  }}
                  onChangeDate={(date) => {
                    const dateTime = date?.getTime()

                    if (dateTime) {
                      setValue('endDate', dateTime)
                    }
                  }}
                />
              </div>
            </FormItem>
          </>
        )}
        {type === 'account' && (
          <FormItem className={styles.options}>
            <FormLabel>Parâmetros</FormLabel>
            <div>
              {parameterOptions.map((option) => (
                <Checkbox
                  key={option.value}
                  id={option.value}
                  label={option.name}
                  checked={(parameter || [])?.includes(option.value)}
                  onChange={() => {
                    let value

                    if (parameter?.includes(option.value)) {
                      value = parameter.filter(
                        (param) => param !== option.value,
                      )
                    } else {
                      value = [...(parameter || []), option.value]
                    }

                    setValue('parameter', value, {
                      shouldValidate: true,
                    })
                  }}
                />
              ))}
            </div>
          </FormItem>
        )}

        <footer>
          <ButtonV2 type="submit" disabled={loading}>
            {loading && <Icon name="loading" />}
            Gerar PDF
          </ButtonV2>
        </footer>
      </form>
    </div>
  )
}

export default Reports
